// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-correct-jsx": () => import("./../../../src/pages/index-correct.jsx" /* webpackChunkName: "component---src-pages-index-correct-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-blog-article-template-jsx": () => import("./../../../src/templates/blogArticleTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-article-template-jsx" */),
  "component---src-templates-blog-listing-template-jsx": () => import("./../../../src/templates/blogListingTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-listing-template-jsx" */),
  "component---src-templates-test-template-jsx": () => import("./../../../src/templates/testTemplate.jsx" /* webpackChunkName: "component---src-templates-test-template-jsx" */)
}

